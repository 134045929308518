import React, { useState } from 'react';
import { lineSpinner } from 'ldrs';
lineSpinner.register();

const Compare = () => {
    const [file1Content, setFile1Content] = useState('');
    const [file2Content, setFile2Content] = useState('');
    const [difference, setDifference] = useState('');
    const [loading, setLoading] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);

    const handleFileChange = (e, setFileContent) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setFileContent(event.target.result);
            };
            reader.readAsText(file);
        }
    };

    const normalizeTvgName = (name) => {
        if (!name) return null;
        return name
            .toLowerCase()
            .replace(/(^\s*tv\s*|\s*hd\s*$)/gi, '')
            .trim();
    };

    const extractTvgNames = (fileContent) => {
        const lines = fileContent.split('\n');
        return lines
            .filter(line => line.includes('#EXTINF'))
            .map(line => {
                const match = line.match(/tvg-name="([^"]*)"/);
                return match ? normalizeTvgName(match[1]) : null;
            })
            .filter(Boolean)
            .filter(name => !name.includes('.mp4'));
    };

    const getFilteredLines = (fileContent, tvgNames) => {
        const lines = fileContent.split('\n');
        let filteredLines = [];
        let currentLineGroup = [];
        lines.forEach(line => {
            if (line.includes('#EXTINF')) {
                const match = line.match(/tvg-name="([^"]*)"/);
                const tvgName = match ? normalizeTvgName(match[1]) : null;
                if (tvgName && tvgNames.includes(tvgName)) {
                    currentLineGroup.push(line);
                }
            } else if (currentLineGroup.length > 0) {
                currentLineGroup.push(line);
                filteredLines.push(...currentLineGroup);
                currentLineGroup = [];
            }
        });
        return filteredLines.join('\n');
    };

    const compareFiles = () => {
        setLoading(true);
        const file1TvgNames = extractTvgNames(file1Content);
        const file2TvgNames = extractTvgNames(file2Content);
        let differences = '';

        const uniqueToFile2 = file2TvgNames.filter(name => !file1TvgNames.includes(name));

        if (uniqueToFile2.length > 0) {
            differences += 'tvg-name uniquement dans le fichier 2:\n' + uniqueToFile2.join('\n') + '\n';

            const filteredContent = getFilteredLines(file2Content, uniqueToFile2);
            createDownloadFile(filteredContent, "file2Filtered.m3u");
        }

        setDifference(differences || 'Aucune différence');
        setLoading(false);
    };

    const createDownloadFile = (content, filename) => {
        const fullContent = "#EXTM3U\n" + content;
        
        const blob = new Blob([fullContent], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        setDownloadUrl(url);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
    };

    return (
        <div className='flex flex-col justify-center items-center mt-20 md:mx-0 mx-4'>
            <h2 className='text-mycolor text-center dark:text-mycolorblack md:text-2xl text-xl font-semibold my-4'>Comparer deux fichiers .m3u avec options</h2>

            <div className='flex md:flex-row flex-col md:space-x-3 md:w-1/2 w-full mx-4 md:mx-0'>
                <div className='md:w-1/2 w-full flex flex-col'>
                    <label className='font-semibold text-sm dark:text-mycolorblack'>Original</label>
                    <input
                        className='border-mycolor dark:border-mycolorblack dark:text-mycolorblack rounded-md border-2'
                        type="file"
                        accept=".m3u"
                        onChange={(e) => handleFileChange(e, setFile1Content)}
                    />
                </div>
                <div className='md:w-1/2 w-full flex flex-col'>
                    <label className='font-semibold text-sm dark:text-mycolorblack'>File to compare</label>
                    <input
                        className='border-mycolor dark:border-mycolorblack rounded-md border-2 dark:text-mycolorblack'
                        type="file"
                        accept=".m3u"
                        onChange={(e) => handleFileChange(e, setFile2Content)}
                    />
                </div>
            </div>
            <div className='md:w-1/2 w-full flex flex-col mb-1'>
                <textarea
                    className='border-mycolor dark:border-mycolorblack dark:bg- rounded-md border-2 p-2 dark:text-mycolorblack'
                    value={difference}
                    readOnly
                    rows="10"
                    cols="80"
                    placeholder='Résultat'
                    style={{ marginTop: '10px', whiteSpace: 'pre-wrap' }}
                />
            </div>
            <button onClick={compareFiles} className='md:w-1/2 font-bold w-full mx-4 md:mx-0 bg-mycolor dark:bg-mycolorblack hover:bg-mycolorhover dark:hover:bg-mycolorhoverblack text-white rounded-md py-2'>
                {loading ? (
                    <l-line-spinner
                        size="30"
                        stroke="3"
                        speed="1" 
                        color="white" 
                    ></l-line-spinner>
                ) : (
                    "Comparer"
                )}
            </button>
            {/* {downloadUrl && (
                <div className='mt-4'>
                    <a href={downloadUrl} download="file2Filtered.m3u" className='text-blue-500 underline'>
                        Télécharger le fichier filtré
                    </a>
                </div>
            )} */}
        </div>
    );
};

export default Compare;